
export const Politica = (props) => {
  return (
    <div className="politica-container">
      <h1 className="titulo">Política para el Tratamiento de Datos Personales</h1>
      <h2 className="subtitulo">1. Tratamiento de Datos Personales (Actuando como Responsable)</h2>
      <p className="parrafo">
        De conformidad con las disposiciones de la Ley Orgánica de Protección de Datos Personales, el TITULAR autoriza a DEVECUA SAS. (en adelante “DEVECUA”) para que lleve a cabo la recolección, almacenamiento, uso, circulación, supresión, transferencia y transmisión (en adelante el “Tratamiento”) de los datos personales que se obtengan y/o se suministren con ocasión del instrumento denominado “Contáctanos”.
      </p>
      <p className="parrafo">
        DEVECUA actuará como responsable del tratamiento de los datos personales proporcionados por el TITULAR. El tratamiento de datos personales se realizará para medir el nivel de satisfacción y/o conformidad con los servicios o productos provistos por DEVECUA; así como, para recibir, analizar y responder los requerimientos, quejas, peticiones, comentarios y/o sugerencias realizadas por el usuario.
      </p>
      
      <p className="parrafo">
        El TITULAR, en virtud de la presente, otorga su consentimiento libre y voluntario a DEVECUA para que lleve a cabo el tratamiento de sus datos personales, de acuerdo a las finalidades, procedimientos y condiciones expresadas en la presente, por lo cual, confirma que su consentimiento es específico, informado e inequívoco.
      </p>
      <p className="parrafo">
        DEVECUA implementará las medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo para la protección de los datos personales del TITULAR. En caso de que se produzca una violación de la seguridad, DEVECUA se lo notificará en un término máximo de tres días.
      </p>
      <p className="parrafo">
        El TITULAR será responsable por la veracidad de la información proporcionada a DEVECUA, por lo que DEVECUA en ningún evento será responsable por los daños, gastos o pérdidas que se generen por la información falsa, errónea o inexacta provista por el TITULAR.
      </p>
      <p className="parrafo">
        El TITULAR tiene derecho a solicitar el acceso, eliminación, rectificación y actualización, oposición, anulación, limitación del tratamiento de los mismos, así como presentar requerimientos y quejas relacionadas con sus derechos a través del correo electrónico: <a className="enlace" href="mailto:infos@devecua.com">info@devecua.com</a>.
      </p>
      <h2 className="subtitulo">2. Transferencia de Datos (Aplicable para los Casos en los que Actúe como Responsable del Tratamiento)</h2>
      <p className="parrafo">
        DEVECUA podrá realizar transferencia de datos personales tanto nacionales como internacionales con el objetivo de cumplir actividades de responsabilidad social. En caso de transferencia internacional, DEVECUA se cerciorará que sea efectuada a jurisdicciones que tutelen la protección y privacidad de datos personales.
      </p>
      <p className="parrafo">
        Los destinatarios estarán sujetos a las mismas obligaciones y medidas de seguridad, técnicas y legales establecidas por el responsable del tratamiento de los datos personales, así como aquellas descritas en la Ley Orgánica de Protección de Datos Personales y demás normativa conexa.
      </p>
    </div>
  );
};
