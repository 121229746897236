export const Services = (props) => {
    return (
        <div id="services">
            <section id="services" >
                <div class="container">
                    <div class="section-header">
                        <h2 class="section-title wow fadeInDown">Sistema para la Gestion de su Negocio</h2>
                        <p class="wow fadeInDown">100% Ecuatoriano y normalizado y autorizado por el SRI.</p>
                    </div>

                    <div class="row">
                        <div class="features">
                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="0ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-futbol-o"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">Comprobantes Electronicos</h4>
                                        <p>Facturas,retenciones,guias,creditos</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="100ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-compass"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">Inventarios</h4>
                                        <p>Controle las ventas con stock y multiples precios</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="200ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-database"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">CuentasCobrar</h4>
                                        <p>Gestione sus cobranzas y retenciones aplicadas a sus ventas.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="300ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-bar-chart"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">Cuentas x Pagar</h4>
                                        <p>Gestione sus pagos de sus compras electronicas.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="400ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-paper-plane-o"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">Contabilidad</h4>
                                        <p>Plan de Cuentas , Diarios y reportes contables.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="300ms" data-wow-delay="500ms">
                                <div class="media service-box">
                                    <div class="pull-left">
                                        <i class="fa fa-bullseye"></i>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading">Reportes</h4>
                                        <p>Exporte su informacion en formato pdf y excel.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};
