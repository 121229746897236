import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Eone } from "./components/eone";
import { Politica } from "./components/politica";
import { Link } from "react-scroll";
import "./App.css";

const App = () => {
  return (
    <Router>
      {/* <nav>
        <ul>
          <li>
            <Link to="home" smooth={true} duration={1000}>Inicio</Link>
          </li>
          <li>
            <Link to="pricing" smooth={true} duration={1000}>Planes</Link>
          </li>
          <li>
            <Link to="services" smooth={true} duration={1000}>Software</Link>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={1000}>Contactar</Link>
          </li>
        </ul>
      </nav> */}

      <Routes>
        <Route path="/" element={<Eone />} />
        <Route path="/politicas" element={<Politica />} />
      </Routes>
    </Router>
  );
};

export default App;
