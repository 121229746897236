import { Meta, Header, Services, Contact, Footer, Pricing } from "./eone/index";

export const Eone = () => {
  return (
    <div>
      <Meta />
      <Header />
      <div id="home">
        <Services />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};
