export const Pricing = () => {
    const plans = [
      { price: "$30", name: "Plan Personal", docs: "120 DOCUMENTOS ELECTRÓNICOS" , soporte: "Soporte via Email" },
      { price: "$60", name: "Plan Emprendedor", docs: "400 DOCUMENTOS ELECTRÓNICOS", soporte: "Soporte Whatsapp Mensajes/Audio"  },
      { price: "$120", name: "Plan Pyme", docs: "1500 DOCUMENTOS ELECTRÓNICOS" , soporte: "Soporte Exclusivo " },
    ];
  
    //const extraPlan = { price: "$25", name: "Firma Electrónica", docs: "" };
  
    return (
      <div id="pricing">
        <section id="pricing">
          <div className="container">
            <div className="section-header">
              <h2 className="section-title wow fadeInDown">Planes y Precios</h2>
              <p className="wow fadeInDown">
                Todos nuestros planes incluyen el IVA 
              </p>
            </div>
  
            {/* 📌 Fila para los 3 planes principales */}
            <div className="row">
              {plans.map((plan, index) => (
                <div key={index} className="col-md-4 col-sm-12">
                  <div className="wow zoomIn" data-wow-duration="400ms" data-wow-delay={`${index * 200}ms`}>
                    <ul className={`pricing ${index === 1 ? "featured" : ""}`}>
                      <li className="plan-header">
                        <div className="price-duration">
                          <span className="price">{plan.price}</span>
                          <span className="duration">Anual</span>
                        </div>
                        <div className="plan-name">{plan.name}</div>
                      </li>
                      <li><strong>{plan.docs}</strong></li>
                      <li><strong>{plan.soporte}</strong></li>
                      <li className="plan-purchase">
                        <a className="btn btn-primary" href="#contact">Contactar!</a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            </div>
        </section>
      </div>
    );
  };
  