export const Header = (props) => {
  return (
    <div id="header">
      <header id="header">
        <nav  id="main-nav"     class="navbar navbar-default navbar-fixed-top"      role="banner"    >
          <div class="container">
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="/">
                <img src="eone2.png" alt="logo" />
              </a>
            </div>

            <div class="collapse navbar-collapse navbar-right">
              <ul class="nav navbar-nav">
                <li class="scroll active">
                  <a href="#hero-banner">Inicio</a>
                </li>
                <li class="scroll">
                  <a href="#pricing">Planes</a>
                </li>
                <li class="scroll">
                  <a href="#services">Software</a>
                </li>
                <li class="scroll">
                  <a href="#contact-us">Contactar</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      
      <section id="hero-banner">
        <div class="banner-inner">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <h2>
                   Factura en segundos, cobra mas rapido y Gestiona mejor tu negocio
                </h2>
                <p>
                Control total de tus ventas con reportes automáticos <br /> 
                Cumple con el SRI sin estrés, factura fácil y rápido <br /> 
                </p>
                <a class="btn btn-primary btn-lg" href="#pricing">
                  Facture Ya!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
