import { Helmet } from "react-helmet";

export const Meta = (props) => {
  return (
    <div>
      <Helmet>
        <title>Devecua eOne</title>
      </Helmet>
    </div>
  );
};
